<script setup lang="ts">
defineProps<{
  /**
   * Hides the logo label and the links in screens < lg.
   */
  minimize?: boolean;
}>();

const nuxtApp = useNuxtApp();
const { t, locale } = useI18n();
const { getLocalizedRoute } = useLocalizedRoute();

const gql = useGql();
const { data, error } = await useLazyAsyncData(
  "footer-links",
  async () => {
    const navReq = await gql({
      operation: "GetFooterNavigationLinks",
      variables: {
        slug: locale.value === "en" ? "other-options" : "diger-secenekler",
      },
    });

    const information = navReq?.page?.generalPageOptions?.pageOtherOptions?.footerLinks?.firstColumn;
    const visas = navReq?.page?.generalPageOptions?.pageOtherOptions?.footerLinks?.thirdColumn;
    const greenCards = navReq?.page?.generalPageOptions?.pageOtherOptions?.footerLinks?.fourthColumn;

    if (!information || !visas || !greenCards) {
      throw createError({
        statusCode: 404,
        statusMessage: "Not Found",
      });
    }

    return {
      information: {
        address: {
          label: information?.address?.label,
          url: information?.address?.googleMapsLink?.url,
        },
        email: information?.email?.label,
        contactNumbers: [information?.firstNumber, information?.secondNumber, information?.thirdNumber] as Array<{ title: string; phoneNumber: string; }>,
      },

      links: [
        {
          label: t("footerNav1Label"),
          to: getLocalizedRoute(locale.value, "index").path,
          children: [
            {
              label: t("navAboutTitle"),
              to: getLocalizedRoute(locale.value, "about").path,
            },
            {
              label: t("footerNavVisas"),
              to: getLocalizedRoute(locale.value, "visas").path,
            },
            {
              label: t("visaMenuGreenCardLabel"),
              to: getLocalizedRoute(locale.value, "greenCard").path,
            },
            {
              label: t("privacyPolicy"),
              to: getLocalizedRoute(locale.value, "privacyPolicy").path,
            },
          ],
        },
        {
          label: visas?.visasTitle ?? "",
          to: getLocalizedRoute(locale.value, "visas").path,
          children: visas?.visas?.nodes?.map(visa => ({ // TODO: Slug is not correct
            label: visa?.name ?? "",
            to: `${getLocalizedRoute(locale.value, "visas").path}/${visa?.slug?.replace(`visa-type-${locale.value}-`, "")}`,
          })),
        },
        {
          label: greenCards?.greenCardsTitle ?? "",
          to: getLocalizedRoute(locale.value, "greenCard").path,
          children: greenCards?.greenCards?.nodes?.map(greenCard => ({ // TODO: Slug is not correct
            label: greenCard?.name ?? "",
            to: `${getLocalizedRoute(locale.value, "greenCard").path}/${greenCard?.slug?.replace(`visa-type-${locale.value}-`, "")}`,
          })),
        },
        {
          label: navReq?.page?.generalPageOptions?.pageOtherOptions?.footerLinks?.fifthColumn?.resourcesTitle ?? "",
          to: "#",
          children: [
            {
              label: "Webinar",
              to: getLocalizedRoute(locale.value, "webinar").path,
            },
            {
              label: "Video",
              to: getLocalizedRoute(locale.value, "video").path,
            },
            {
              label: "Blog",
              to: getLocalizedRoute(locale.value, "blog").path,
            },
          ],
        },
      ] satisfies Array<{
        label: string;
        to: string;
        children?: Array<{
          label: string;
          to: string;
        }>;
      }>,
    };
  },
  {
    watch: [locale],
  },
);

// We do not throw an error here because we want to show the footer even if the data is not fetched.
watch(error, () => {
  nuxtApp.$sentry.captureError(error?.value);
});

const footerBottomLinks = computed(() => {
  return [
    {
      label: t("navTermsOfUseTitle"),
      to: getLocalizedRoute(locale.value, "termsOfUse").path,
    },
    {
      label: t("navPrivacyPolicyTitle"),
      to: getLocalizedRoute(locale.value, "privacyPolicy").path,
    },
  ];
});
</script>

<template>
  <div>
    <UFooter
      class="hidden lg:block"
      :ui="{
        top: {
          wrapper: 'border-gray-100 border-t pt-6 pb-4',
        } as any,
        bottom: {
          wrapper: 'border-gray-100 border-t py-4',
        } as any,
      }"
    >
      <template #top>
        <FooterColumns
          :links="data?.links.map(link => ({
            label: link.label,
            to: link.to,

            children: Object.values(link.children ?? {}).map(child => ({
              label: child.label,
              to: child.to,
            })),
          }))"
          :ui="{
            wrapper: 'grid grid-cols-3 gap-8',
            center: 'gap-10 xl:gap-20 justify-end col-span-2',
            list: 'space-y-2 mt-4',
          }"
        >
          <template #left>
            <div class="flex h-full flex-col items-start">
              <NuxtLinkLocale
                to="/"
                :aria-label="t('footerLogoLabel')"
              >
                <LogoLabelLight class="h-14 shrink-0" />
              </NuxtLinkLocale>

              <div class="mt-8 space-y-3">
                <p
                  class="text-gray-600 text-sm"
                >
                  <NuxtLink
                    :to="data?.information?.address?.url ?? ''"
                    class="hover:underline"
                    target="_blank"
                  >
                    <span
                      v-external-link
                      v-html="data?.information?.address?.label"
                    />
                  </NuxtLink>
                </p>
                <p class="text-gray-600 text-sm">
                  <NuxtLink
                    :to="`mailto:${data?.information?.email}`"
                    target="_blank"
                    class="hover:underline"
                  >
                    {{ data?.information?.email }}
                  </NuxtLink>
                </p>
                <p
                  v-for="(contact, index) in data?.information?.contactNumbers ?? []"
                  :key="index"
                  class="text-gray-600 flex flex-col text-sm"
                >
                  <span
                    v-if="contact?.title"
                    class="font-semibold"
                  >{{ contact?.title }}</span>
                  <NuxtLink
                    :to="`tel:${contact?.phoneNumber}`"
                    target="_blank"
                    class="hover:underline"
                  >
                    {{ contact?.phoneNumber }}
                  </NuxtLink>
                </p>
              </div>

              <div class="mt-12 flex gap-3">
                <UButton
                  color="gray"
                  variant="outline"
                  class="ring-gray-200 rounded-full p-2 shadow"
                  :to="SOCIALS.instagram"
                  target="_blank"
                  :aria-label="t('footerInstagramLabel')"
                >
                  <IconInstagramSolid class="size-6" />
                </UButton>
                <UButton
                  color="gray"
                  variant="outline"
                  class="ring-gray-200 rounded-full p-3 shadow"
                  :to="SOCIALS.linkedin"
                  target="_blank"
                  :aria-label="t('footerLinkedinLabel')"
                >
                  <IconLinkedin class="size-4" />
                </UButton>
                <UButton
                  color="gray"
                  variant="outline"
                  class="ring-gray-200 rounded-full p-2 shadow"
                  :to="SOCIALS.youtube"
                  :aria-label="t('footerYoutubeLabel')"
                  target="_blank"
                >
                  <IconYoutube class="size-6" />
                </UButton>
                <UButton
                  color="gray"
                  variant="outline"
                  class="ring-gray-200 rounded-full p-2.5 shadow"
                  :to="SOCIALS.twitter"
                  :aria-label="t('footerTwitterLabel')"
                  target="_blank"
                >
                  <UIcon
                    name="i-simple-icons-twitter"
                    class="size-5 text-text-title"
                  />
                </UButton>
              </div>
            </div>
          </template>
        </FooterColumns>
      </template>

      <template #left>
        <span class="text-sm">
          © {{ new Date().getFullYear() }} Grape Law Firm PLLC
        </span>
      </template>

      <template #right>
        <div
          v-for="(item, index) in footerBottomLinks"
          :key="index"
        >
          <UButton
            :label="item.label.toLocaleUpperCase(locale)"
            variant="link"
            size="xs"
            color="black"
            :to="item.to"
          />
        </div>
      </template>
    </UFooter>

    <div
      class="flex w-full flex-col items-center lg:hidden"
      :class="{
        'border-gray-100 border-t': !minimize,
      }"
    >
      <NuxtLink
        v-if="!minimize"
        :to="getLocalizedRoute(locale, 'index').path"
        class="mt-10"
        :aria-label="t('footerLogoLabel')"
      >
        <Logo class="h-10 shrink-0" />
      </NuxtLink>

      <div
        v-if="!minimize"
        class="mt-10 flex flex-col gap-6 text-center"
      >
        <NuxtLink
          v-for="(link, idx) in data?.links ?? []"
          :key="idx"
          :to="link?.to"
          :aria-label="link?.label"
          class="text-base font-semibold text-text-title hover:text-blue-700"
        >
          {{ link?.label }}
        </NuxtLink>
      </div>

      <div class="flex w-full justify-center">
        <div class="relative mt-12 flex w-full justify-center gap-5">
          <UDivider class="absolute inset-y-0" />
          <UButton
            color="gray"
            variant="outline"
            :aria-label="t('footerInstagramLabel')"
            class="ring-gray-200 z-10 rounded-full bg-white p-2 shadow"
            :to="SOCIALS.instagram"
            target="_blank"
          >
            <IconInstagramSolid class="size-6" />
          </UButton>
          <UButton
            color="gray"
            variant="outline"
            :aria-label="t('footerLinkedinLabel')"
            class="ring-gray-200 z-10 rounded-full bg-white p-3 shadow"
            :to="SOCIALS.linkedin"
            target="_blank"
          >
            <IconLinkedin class="size-4" />
          </UButton>
          <UButton
            color="gray"
            variant="outline"
            :aria-label="t('footerYoutubeLabel')"
            class="ring-gray-200 z-10 rounded-full bg-white p-2 shadow"
            :to="SOCIALS.youtube"
            target="_blank"
          >
            <IconYoutube class="size-6" />
          </UButton>
          <UButton
            color="gray"
            variant="outline"
            :aria-label="t('footerFacebookLabel')"
            class="ring-gray-200 z-10 rounded-full bg-white p-2.5 shadow"
            :to="SOCIALS.twitter"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-twitter"
              class="size-5 text-text-title"
            />
          </UButton>
        </div>
      </div>

      <span class="mb-10 mt-6 text-sm text-text-paragraph">
        © {{ new Date().getFullYear() }} Grape Law Firm PLLC
      </span>
    </div>
  </div>
</template>
