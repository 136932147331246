import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/vercel/path0/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_aRl2YLnTcb from "/vercel/path0/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_izaWKZ8rEu from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_X7whXyrIWR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_jBToDIZpFa from "/vercel/path0/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import scrollbars_client_Eg4DPJPcY6 from "/vercel/path0/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_1aypKNZ222 from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import autoAnimate_4HQGapz9xs from "/vercel/path0/plugins/autoAnimate.ts";
import externalLink_xmXClNpE4R from "/vercel/path0/plugins/externalLink.ts";
import feedbucket_client_bcif5LEtbp from "/vercel/path0/plugins/feedbucket.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import seo_6xeD0pAsTm from "/vercel/path0/plugins/seo.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  defaults_aRl2YLnTcb,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_jBToDIZpFa,
  scrollbars_client_Eg4DPJPcY6,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  autoAnimate_4HQGapz9xs,
  externalLink_xmXClNpE4R,
  feedbucket_client_bcif5LEtbp,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk,
  seo_6xeD0pAsTm
]