import type { WritableComputedRef } from "@vue/reactivity";
import type { OgType } from "~/types/seo";

export default defineNuxtPlugin({
  name: "seoPlugin",
  parallel: true,
  setup: async (nuxtApp) => {
    const route = useRoute();
    const gql = useGql();
    const { siteUrl } = useRuntimeConfig().public;
    const { getLocalizedRoute } = useLocalizedRoute();
    const { locale, t } = nuxtApp.$i18n as {
      locale: WritableComputedRef<string>;
      t: (key: string) => string;
    };

    const isDynamicRoute = (path: string) => {
      return path.includes("/blog/");
    };

    if (!isDynamicRoute(route.fullPath)) {
      const clearLangParamFromPath = (path: string) => {
        if (path === "/")
          return getLocalizedRoute(locale.value, "index").backendSlug;
        const langPattern = new RegExp(`^\/(${locale.value})\/|\/(${locale.value})$`, "g");
        return path.replace(langPattern, "/");
      };

      const setSeoData = async () => {
        const isIndexPage = route.fullPath === "/";
        const slug = clearLangParamFromPath(route.fullPath) || "";
        const seoReq = gql("GetPageSeo", {
          slug,
        });

        const seo = await seoReq;

        const title = seo.page?.seo?.title || "";

        if (seo.page?.seo) {
          useSeoMeta({
            title,
            description: seo.page?.seo.description,
            ogTitle: title,
            ogDescription: seo.page?.seo.description,
            ogImageUrl: seo.page?.seo.openGraph?.image?.secureUrl,
            ogImageSecureUrl: seo.page?.seo.openGraph?.image?.secureUrl,
            ogImageAlt: title,
            ogType: seo.page?.seo.openGraph?.type as OgType || "website",
            twitterCard: "summary_large_image",
            twitterTitle: title,
            twitterDescription: seo.page?.seo.description,
            twitterImage: seo.page?.seo.openGraph?.image?.secureUrl,
            twitterImageAlt: title,
          });

          const i18nHead = useLocaleHead({
            addSeoAttributes: true,
            identifierAttribute: "id",
          });

          // Check if x-default is present and create it if it's missing
          const hasXDefault = i18nHead.value.link?.some((link: any) => link.hreflang === "x-default");
          const xDefaultLink = !hasXDefault
            ? {
                id: "i18n-xd",
                rel: "alternate",
                // href: `${siteUrl}${defaultLocale}${route.fullPath}`,
                href: `${siteUrl}${route.fullPath}`,
                hreflang: "x-default",
              }
            : null;

          useHead({
            htmlAttrs: {
              lang: i18nHead.value.htmlAttrs!.lang,
            },
            link: [
              ...(i18nHead.value.link || []),
              ...(xDefaultLink ? [xDefaultLink] : []),
            ],
            meta: [...(i18nHead.value.meta || [])],
            title,
          });

          const schemaWebPage = {
            name: title,
            datePublished: seo.page?.seo.openGraph?.articleMeta?.publishedTime ?? undefined,
            dateModified: seo.page?.seo.openGraph?.updatedTime || seo.page?.seo.openGraph?.articleMeta?.modifiedTime,
            about: { "@id": `${siteUrl}${getLocalizedRoute(locale.value, "index").path}/#organization` },
            isPartOf: { "@id": `${siteUrl}${getLocalizedRoute(locale.value, "index").path}/#website` },
            inLanguage: locale.value,
          };

          useSchemaOrg([
            defineWebPage({
              ...schemaWebPage,
            }),
            defineBreadcrumb({
              itemListElement: [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": t("breadcrumbsHome"),
                  "item": `${siteUrl}${getLocalizedRoute(locale.value, "index").path}`,
                },
                ...(isIndexPage
                  ? []
                  : [
                      {
                        "@type": "ListItem",
                        "position": 2,
                        "name": seo.page?.seo.openGraph?.title,
                        "item": `${siteUrl}${route.fullPath}`,
                      },
                    ]),
              ],
            }),
          ]);
        }
      };

      nuxtApp.hook("page:finish", async () => {
        await setSeoData();
      });
    }
  },
});
