import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as _91blogSlug_93rCuzaGu5kSMeta } from "/vercel/path0/pages/blog/[blogSlug].vue?macro=true";
import { default as _91blogCategory_937MesZrV2W0Meta } from "/vercel/path0/pages/blog/category/[blogCategory].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as terms_45of_45useuG8Ysh8zj7Meta } from "/vercel/path0/pages/terms-of-use.vue?macro=true";
import { default as testimonialxaM0zu0zcXMeta } from "/vercel/path0/pages/testimonial.vue?macro=true";
import { default as _91slug_93vQcVIuRYbVMeta } from "/vercel/path0/pages/us-immigration/[category]/[slug].vue?macro=true";
import { default as _91slug_93AbhSJw2etyMeta } from "/vercel/path0/pages/us-immigration/[slug].vue?macro=true";
import { default as video9ly87Y8R55Meta } from "/vercel/path0/pages/video.vue?macro=true";
import { default as webinarCViv5MmekeMeta } from "/vercel/path0/pages/webinar.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___en",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___tr",
    path: "/tr/hakkimizda",
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "blog-blogSlug___en",
    path: "/blog/:blogSlug()",
    component: () => import("/vercel/path0/pages/blog/[blogSlug].vue").then(m => m.default || m)
  },
  {
    name: "blog-blogSlug___tr",
    path: "/tr/blog/:blogSlug()",
    component: () => import("/vercel/path0/pages/blog/[blogSlug].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-blogCategory___en",
    path: "/blog/category/:blogCategory()",
    component: () => import("/vercel/path0/pages/blog/category/[blogCategory].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-blogCategory___tr",
    path: "/tr/blog/kategori/:blogCategory()",
    component: () => import("/vercel/path0/pages/blog/category/[blogCategory].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___tr",
    path: "/tr/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___tr",
    path: "/tr/bize-ulasin",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___tr",
    path: "/tr/gizlilik-politikasi",
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___tr",
    path: "/tr/ara",
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___en",
    path: "/terms-of-use",
    component: () => import("/vercel/path0/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___tr",
    path: "/tr/kullanim-kosullari",
    component: () => import("/vercel/path0/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "testimonial___en",
    path: "/testimonial",
    component: () => import("/vercel/path0/pages/testimonial.vue").then(m => m.default || m)
  },
  {
    name: "testimonial___tr",
    path: "/tr/muvekkillerimizden",
    component: () => import("/vercel/path0/pages/testimonial.vue").then(m => m.default || m)
  },
  {
    name: "us-immigration-category-slug___en",
    path: "/us-immigration/:category()/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "us-immigration-category-slug___tr",
    path: "/tr/amerika-gocmenlik/:category()/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "us-immigration-slug___en",
    path: "/us-immigration/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[slug].vue").then(m => m.default || m)
  },
  {
    name: "us-immigration-slug___tr",
    path: "/tr/amerika-gocmenlik/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[slug].vue").then(m => m.default || m)
  },
  {
    name: "video___en",
    path: "/video",
    component: () => import("/vercel/path0/pages/video.vue").then(m => m.default || m)
  },
  {
    name: "video___tr",
    path: "/tr/video",
    component: () => import("/vercel/path0/pages/video.vue").then(m => m.default || m)
  },
  {
    name: "webinar___en",
    path: "/webinar",
    component: () => import("/vercel/path0/pages/webinar.vue").then(m => m.default || m)
  },
  {
    name: "webinar___tr",
    path: "/tr/webinar",
    component: () => import("/vercel/path0/pages/webinar.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub5X4Ei38PMg
  }
]