<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps<{
  error: NuxtError;
}>();

const { t, locale } = useI18n();
const { getLocalizedRoute } = useLocalizedRoute();

useSeoMeta({
  title: props.error.statusCode === 404 ? t("error.pageNotFound") : t("error.anErrorOccurred"),
});
</script>

<template>
  <div>
    <Header />

    <main class="bg-snow-50">
      <UPageError
        :status="error.statusCode"
        :name="error.statusCode === 404 ? t('error.pageNotFound') : t('error.anErrorOccurred')"
        message=""
        :clear-button="{
          label: t('error.goBackHome'),
          to: getLocalizedRoute(locale, 'index').path,
        }"
        :ui="{
          name: 'sm:text-4xl',
        }"
      />
    </main>

    <Footer />
  </div>
</template>
